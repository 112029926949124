import { isEmpty } from 'lodash'
import { countries } from '~/utils/address'
import { numberWithCommas } from '~/utils/number'

const weight = shipment =>
  shipment.GrossWeightKgs
    ? `${numberWithCommas(shipment.GrossWeightLbs)} / ${numberWithCommas(
        shipment.GrossWeightKgs
      )}`
    : '-----'

const weightKg = shipment =>
  shipment.GrossWeightKgs
    ? `${numberWithCommas(shipment.GrossWeightKgs)} kg`
    : ''

const quantity = shipment =>
  shipment.Quantity && shipment.QuantityUnit
    ? `${shipment.Quantity} ${shipment.QuantityUnit}`
    : '-----'

const measurement = shipment =>
  shipment.Measurement && shipment.MeasurementUnit
    ? `${shipment.Measurement} ${shipment.MeasurementUnit}`
    : '-----'

const carrierAddress = shipment =>
  shipment
    ? `${shipment.CarrierAddress}\n${shipment.CarrierCity} ${
        shipment.CarrierState
      } ${shipment.CarrierZip ? shipment.CarrierZip[4] : ''}`
    : '-----'

const vesselCountry = shipment => {
  if (!shipment) {
    return '-----'
  }
  const id = countries.findIndex(
    country => country.code === shipment.VesselCountry
  )
  return countries[id] ? countries[id].name : shipment.VesselCountry
}

export const billType = shipment => {
  const billTypeCode = shipment.BillTypeCode
  const billTypeLabels = {
    'Regular Bill': 'Straight Bill',
    'In-bond Automated': 'In-bond House',
    'Empty Equipment IIT': 'Empty Equipment',
    'Master Bill': 'Master Bill',
    'House Bill': 'House Bill',
    'Master FROB': 'FROB Master',
    'House FROB': 'FROB House',
    'Simple BOL FROB': 'FROB Straight',
    'Master BOL w/in-bond': 'In-bond Master'
  }

  return billTypeLabels[billTypeCode]
}

export const emptyText = 'Not Available'

const transit = [
  { text: 'Carrier', key: 'CarrierName' },
  { text: 'Carrier Code', key: 'CarrierCode' },
  { text: 'Carrier Address', value: carrierAddress },
  { text: 'Vessel Country', value: vesselCountry },
  { text: 'Foreign Port', key: 'ForeignPort' },
  { text: 'Voyage Number', key: 'VoyageNumber' },
  { text: 'Country Of Origin', key: 'CountryOfOrigin' },
  { text: 'Vessel Name', key: 'VesselName' },
  { text: 'U.S. Port', key: 'DestinationPort' },
  { text: 'Master Bill Of Lading', key: 'MasterBillOfLading' },
  { text: 'Weight (Lb/Kg)', value: weight },
  { text: 'Quantity', value: quantity },
  { text: 'Container Count', key: 'NumberOfContainers' },
  { text: 'Place Of Receipt', key: 'PlaceOfReceipt' },
  { text: 'Distribution Port', key: 'DistributionPort' },
  { text: 'House vs Master', key: 'BillTypeCode' },
  { text: 'Measurement', value: measurement },
  { text: 'In-Bond Entry Type', key: 'InBondEntryType' }
]

const contactInfo = [
  { key: 'Email Address', text: 'Email Address' },
  { key: 'Phone Number', text: 'Phone Number' },
  { key: 'Fax Number', text: 'Fax Number' }
]

const billOfLadingSummary = [
  { key: 'MasterBillOfLading', text: 'Master Bill' },
  { key: 'ArrivalDate', text: 'Arrival Date' },
  { value: billType, text: 'Bill Type' },
  { key: 'CalculatedTEU', text: 'Total TEUs' },
  { value: weightKg, text: 'Weight' },
  { key: 'Consignee', text: 'Consignee' },
  { key: 'NotifyParty', text: 'Notify Party' },
  { key: 'Shipper', text: 'Shipper' },
  { key: 'CountryOfOrigin', text: 'Country of Origin' },
  { key: 'DestinationPort', text: 'Port of Unlading' },
  { key: 'DistributionPort', text: 'Port of Destination' }
]

const consigneeSummary = [
  { key: 'ConsigneeAddress', text: 'Declared Address' },
  { key: 'ConsigneeCity', text: 'City' },
  { key: 'ConsigneeState', text: 'State' },
  { key: 'ConsigneeZipCode', text: 'Zip' }
]

const notifyPartySummary = [
  { key: 'NotifyParty', text: 'Notify Party Name' },
  { key: 'NotifyPartyAddress', text: 'Declared Address' }
]

const shipperSummary = [
  { key: 'ShipperAddress', text: 'Address' },
  { key: 'CountryOfOrigin', text: 'Country' },
  { key: 'PlaceOfReceipt', text: 'Place of Receipt' }
]

const carrierSummary = [
  { key: 'CarrierAddress', text: 'Address' },
  { key: 'CarrierCity', text: 'City' },
  { key: 'CarrierState', text: 'State' },
  { key: 'VesselName', text: 'Vessel Name' },
  { value: vesselCountry, text: 'Vessel Country' },
  { key: 'VoyageNumber', text: 'Voyage Number' },
  { key: 'ArrivalDate', text: 'Arrival Date' },
  { key: 'ForeignPort', text: 'Foreign Port' },
  { key: 'DestinationPort', text: 'US Port' }
]

const shipper = shipment => ({
  value: shipment.Shipper || '-NOT AVAILABLE-',
  address: shipment.ShipperAddress || '-NOT AVAILABLE-',
  contact: shipment.ShipperContactInfo || '-NOT AVAILABLE-',
  id: shipment.ShipperId || null
})

const consignee = shipment => ({
  value: shipment.Consignee || '-NOT AVAILABLE-',
  address: shipment.ConsigneeAddress || '-NOT AVAILABLE-',
  contact: shipment.ConsigneeContactInfo || '-NOT AVAILABLE-',
  id: shipment.ConsigneeId || null
})

const notifyParty = shipment => ({
  value: shipment.NotifyParty || '-NOT AVAILABLE-',
  address: shipment.NotifyPartyAddress || '-NOT AVAILABLE-',
  contact: shipment.NotifyContact || '-NOT AVAILABLE-',
  id: shipment.NotifyPartyId || null
})

const contact = [
  {
    text: 'Consignee',
    value: consignee
  },
  {
    text: 'Shipper',
    value: shipper
  },
  {
    text: 'Notify Party',
    value: notifyParty
  }
]

/**
 * Compute value of a shipment field based on its value from
 * the shipment details returned from search service and the field's specified configuration
 *
 * @param {Object} field - shipment field configuration
 * @param {Object} details - shipment details
 * @param {*} placeholder - value to assign when field key is specified and its value is null
 *
 * @returns {Object} Name/label and computed value of the shipment field
 */
const computeValue = (field, details, placeholder) => {
  let value

  if (typeof field.value === 'function') value = field.value(details)

  if (typeof field.value === 'string') value = field.value

  if (typeof field.key === 'string') value = details[field.key] || placeholder

  return { name: field.text, value }
}

export const getTransitDetails = details =>
  transit.map(field => computeValue(field, details, '-----'))

export const getContactDetails = details =>
  contact.map(field => computeValue(field, details, ''))

/**
 * Get Bill of Lading Summary
 *
 * @param {Object} details - shipment details
 *
 * @returns {Array.<Object>} Name/label and computed value of the BOL summary fields
 */
export const getBillOfLadingSummary = details => {
  return billOfLadingSummary.map(field => {
    const placeholder = ['Consignee', 'Shipper'].includes(field.text)
      ? emptyText
      : ''
    return computeValue(field, details, placeholder)
  })
}

/**
 * Get Consignee Summary
 *
 * @param {Object} details - shipment details
 *
 * @returns {Array.<Object>} Name/label and computed value of the consignee summary fields
 * including the consignee's contact details
 */
export const getConsigneeSummary = shipment => {
  const summary = consigneeSummary.map(field =>
    computeValue(field, shipment, '')
  )

  if (shipment.ConsigneeContactInfo) {
    summary.push(
      ...contactInfo.map(field =>
        computeValue(field, shipment.ConsigneeContactInfo, '')
      )
    )
  }

  return summary
}

/**
 * Get Notify Party Summary
 *
 * @param {Object} details - shipment details
 *
 * @returns {Array.<Object>} Name/label and computed value of the notify party summary fields
 */
export const getNotifyPartySummary = shipment => {
  return notifyPartySummary.map(field => computeValue(field, shipment, ''))
}

/**
 * Get Shipper Summary
 *
 * @param {Object} details - shipment details
 *
 * @returns {Array.<Object>} Name/label and computed value of the shipper summary fields
 * including the shipper's contact details
 */
export const getShipperSummary = shipment => {
  const summary = shipperSummary.map(field => computeValue(field, shipment, ''))

  if (shipment.ShipperContactInfo) {
    summary.push(
      ...contactInfo.map(field =>
        computeValue(field, shipment.ShipperContactInfo, '')
      )
    )
  }

  return summary
}

/**
 * Get Carrier Summary
 *
 * @param {Object} details - shipment details
 *
 * @returns {Array.<Object>} Name/label and computed value of the carrier summary fields
 */
export const getCarrierSummary = shipment => {
  return carrierSummary.map(field => computeValue(field, shipment, ''))
}

/**
 * Get Cargo Summary
 * - a consolidated list of cargoes of all the shipment's containers
 *
 * @param {Object} details - shipment details
 *
 * @returns {Array.<Object>} Summary of the cargo details
 */
export const getCargoSummary = shipment => {
  const containers = shipment.Containers || []

  if (!containers.length) return []

  const summary = containers.reduce((cargoList, container) => {
    if (!container) return cargoList

    const cargoes = container.Cargoes || []
    if (!cargoes.length) return cargoList

    cargoes.forEach(cargo => {
      const quantity = cargo['Piece Count'] || 0
      cargoList.push({
        Container: container['Container Number'] || emptyText,
        Quantity: `${quantity} ${shipment['QuantityUnit']}`,
        Description: cargo['Description'],
        HSCode: cargo['HS Code'] || emptyText
      })
    })

    return cargoList
  }, [])

  return summary
}

/**
 * Generate title for the shipment details
 *
 * @param {Object} shipment - shipment details
 *
 * @returns {String}
 */
export const getShipmentTitle = shipment => {
  if (shipment.Consignee) return `Shipment to ${shipment.Consignee}`
  if (shipment.Shipper) return `Shipment from ${shipment.Shipper}`

  const productKeyword =
    (shipment.Containers &&
      shipment.Containers.length &&
      shipment.Containers[0].Cargoes.length &&
      shipment.Containers[0].Cargoes[0].Keywords.length &&
      shipment.Containers[0].Cargoes[0].Keywords[0]) ||
    ''
  if (productKeyword && productKeyword.length < 35 && shipment.CountryOfOrigin)
    return `${productKeyword} from ${shipment.CountryOfOrigin}`

  if (!isEmpty(shipment)) return `${billType(shipment)}, undeclared consignee`

  return 'Shipment Details'
}
