<template>
  <div class="card-base">
    <div class="card-head-bar">
      <div class="head-title">
        <div
          v-if="sectionTitle.label"
          class="title-label">
          {{ sectionTitle.label }}
        </div>
        <component
          :is="sectionTitle.link ? 'nuxt-link' : 'span'"
          :to="sectionTitle.link"
          class="title-name"><h2>{{ sectionTitle.name }}</h2></component>
      </div>
      <div class="head-toolbar"/>
    </div>

    <div class="card-main">
      <slot name="card-main">
        <table class="table">
          <tbody>
            <tr
              v-for="(field, index) in summary"
              :key="index"
              :class="{ 'empty-row': !field }">
              <td v-if="!field">&nbsp;</td>
              <td
                v-if="field"
                class="leftmost-column label">{{ field.name }}</td>
              <td v-if="field">
                <component
                  :is="field.component || 'span'"
                  :to="field.link">{{ field.value }}</component>
              </td>
            </tr>
          </tbody>
        </table>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShipmentDetailSection',
  props: {
    summary: {
      type: Array,
      default: () => {
        return []
      }
    },
    sectionTitle: {
      type: Object,
      required: true
    }
  }
}
</script>
