<template>
  <div
    v-loading="isFetching"
    :class="{ loading: !shipment.BillOfLading }"
    class="shipment-details">
    <div class="section-inner">
      <breadcrumb :sub-pages="breadcrumbs"/>

      <h1>{{ shipmentHeadline }}</h1>

      <div class="row">
        <div class="col-md-9 col-xl-10 summary-section">
          <!-- Bill of Lading Section -->
          <shipment-detail-section
            v-if="billOfLadingSummary.length"
            id="bill-of-lading"
            :section-title="{ label: 'Bill of Lading Number', name: shipment.BillOfLading }"
            class="bol-section" >
            <template slot="card-main">
              <table class="table">
                <tbody>
                  <tr
                    v-for="(field, index) in billOfLadingSummary"
                    :key="index">
                    <td class="leftmost-column label">{{ field[0].name }}</td>
                    <td>
                      <component
                        :is="field[0].component || 'span'"
                        :to="field[0].link">{{ field[0].value }}</component>
                    </td>
                    <td class="label">
                      <template v-if="field[1]">{{ field[1].name }}</template>
                    </td>
                    <td>
                      <component
                        v-if="field[1]"
                        :is="field[1].component || 'span'"
                        :to="field[1].link">{{ field[1].value }}</component>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </shipment-detail-section>

          <!-- Consignee Section -->
          <shipment-detail-section
            v-if="consigneeSummary.length"
            id="consignee"
            :summary="consigneeSummary"
            :section-title="{ label: 'Consignee', name: shipment.Consignee, link: getCompanyProfileLink(shipment.ConsigneeId) }" />

          <!-- Shipper Section -->
          <shipment-detail-section
            v-if="shipperSummary.length"
            id="shipper"
            :summary="shipperSummary"
            :section-title="{ label: 'Shipper', name: shipment.Shipper, link: getCompanyProfileLink(shipment.ShipperId) }" />

          <!-- Carrier Section -->
          <shipment-detail-section
            v-if="carrierSummary.length"
            id="carrier"
            :summary="carrierSummary"
            :section-title="{ label: 'Carrier', name: shipment.CarrierName }" />

          <!-- Cargo Section -->
          <shipment-detail-section
            v-if="cargoSummary.length"
            id="cargo"
            :section-title="{ name: 'Cargo' }"
            class="cargo-section" >
            <template slot="card-main">
              <table class="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Container</th>
                    <th>Quantity</th>
                    <th>Description</th>
                    <th>HS Code</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(cargo, index) in paginatedCargoes"
                    :key="index">
                    <td>{{ ((currentPage - 1) * pageLimit) + index + 1 }}</td>
                    <td>{{ cargo.Container }}</td>
                    <td>{{ cargo.Quantity }}</td>
                    <td>{{ cargo.Description }}</td>
                    <td>{{ cargo.HSCode }}</td>
                  </tr>
                </tbody>
              </table>
              <el-pagination
                v-show="totalCargoes"
                :current-page="currentPage"
                :page-sizes="pageLimits"
                :page-size="pageLimit"
                :total="totalCargoes"
                :limit="totalCargoes"
                :data="paginatedCargoes"
                class="card-footer"
                @size-change="handleLimitChange"
                @current-change="handlePageChange"/>
            </template>
          </shipment-detail-section>
        </div>

        <!-- Shortcut Menu -->
        <div class="col-md-2 col-xl-2 sidenav">
          <h3>Shortcuts</h3>
          <nav class="vertical">
            <ul>
              <li
                v-for="(section, index) in sections"
                :key="index">
                <a
                  v-scroll-to="`#${section.id}`"
                  :title="section.title"
                  href>{{ section.title }}</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { upperCase } from 'lodash'
import { mapState, mapMutations } from 'vuex'
import {
  emptyText,
  getBillOfLadingSummary,
  getConsigneeSummary,
  getNotifyPartySummary,
  getShipperSummary,
  getCarrierSummary,
  getCargoSummary,
  getShipmentTitle
} from '~/utils/shipmentDetails'
import { cleanAddress } from '~/utils/address'
import { buildQueryParams } from '~/utils/url'
import Breadcrumb from '~/components/common/Breadcrumb'
import ShipmentDetailSection from '~/components/shipment/ShipmentDetailSection'
import hideSidebarMixin from '~/mixins/hideSidebar'
import { convertToTwoDigitDecimal } from '~/utils/number'

export default {
  head() {
    return {
      title: this.shipmentHeadline
    }
  },

  fetch({ store, from }) {
    if (!from.name.includes('country-tradeType-shipment-id')) {
      store.commit('setViewedShipment', {})
    }
  },

  components: {
    Breadcrumb,
    ShipmentDetailSection
  },

  mixins: [hideSidebarMixin],

  data() {
    return {
      isFetching: false,
      countryNames: {
        us: 'United States'
      },

      // cargo section pagination
      currentPage: 1,
      pageLimits: [10, 20, 50, 100],
      pageLimit: 10
    }
  },

  computed: {
    ...mapState({
      shipments: state => state.search.shipments,
      shipment: state => state.shipment,
      country: state => state.country,
      tradeType: state => state.tradeType
    }),

    sections() {
      const sections = [{ id: 'bill-of-lading', title: 'Bill of Lading' }]

      if (this.shipment.Consignee)
        sections.push({ id: 'consignee', title: 'Consignee' })
      if (this.shipment.Shipper)
        sections.push({ id: 'shipper', title: 'Shipper' })

      sections.push(
        { id: 'carrier', title: 'Carrier' },
        { id: 'cargo', title: 'Cargo' }
      )

      return sections
    },

    noMasterBill() {
      const billTypeCode = this.shipment.BillTypeCode || ''
      return (
        billTypeCode.includes('Master') ||
        billTypeCode.includes('Regular') ||
        billTypeCode.includes('Simple')
      )
    },

    masterBillOfLadingLink() {
      if (this.shipment.MasterBillOfLading) {
        return `/${this.$route.params.country}/${
          this.$route.params.tradeType
        }/shipment/${this.shipment.MasterBillOfLading}`
      }

      return null
    },

    billOfLadingSummary() {
      const summary = getBillOfLadingSummary(this.shipment)
      const formatted = summary
        .reduce((acc, val, index) => {
          val = this.formatFieldValue(val)

          if (!val.value) return acc

          if (acc[Math.ceil(index / 2)]) {
            acc[Math.ceil(index / 2)].push(val)
          } else {
            acc[Math.ceil(index / 2)] = [val]
          }
          return acc
        }, [])
        .filter(item => {
          return !!item
        })

      return formatted
    },

    consigneeSummary() {
      if (!this.shipment.Consignee) return []

      const formatted = this.formatSummary(getConsigneeSummary(this.shipment))

      // push notify party summary to consignee summary
      if (this.notifyPartySummary.length) {
        if (formatted.length) formatted.push('')
        formatted.push(...this.notifyPartySummary)
      }

      return formatted
    },

    notifyPartySummary() {
      if (!this.shipment.NotifyParty) return []
      return this.formatSummary(getNotifyPartySummary(this.shipment))
    },

    shipperSummary() {
      if (!this.shipment.Shipper) return []
      return this.formatSummary(getShipperSummary(this.shipment))
    },

    carrierSummary() {
      return this.formatSummary(getCarrierSummary(this.shipment), 3)
    },

    cargoSummary() {
      return getCargoSummary(this.shipment) || []
    },

    totalCargoes() {
      return this.cargoSummary.length
    },

    paginatedCargoes() {
      const { start, end } = this.findIndexes(this.currentPage, this.pageLimit)
      return this.cargoSummary.slice(start, end)
    },

    shipmentHeadline() {
      return upperCase(getShipmentTitle(this.shipment)) || 'Shipment Details'
    },

    shipmentId() {
      return this.$route.params.id
    },

    breadcrumbs() {
      const breadcrumbs = [
        {
          label: this.countryNames[this.country]
            ? this.countryNames[this.country]
            : this.country
        },
        { label: this.tradeType }
      ]

      if (!this.noMasterBill) {
        breadcrumbs.push({
          label: `(Master) ${this.shipment.MasterBillOfLading}`,
          link: this.masterBillOfLadingLink
        })
      }

      breadcrumbs.push({ label: this.shipment.BillOfLading })

      return breadcrumbs
    }
  },

  created() {
    this.$store.dispatch('search/initialize')
  },

  mounted() {
    this.fetchShipmentDetails(this.shipmentId)
  },

  methods: {
    ...mapMutations(['setViewedShipment']),

    /**
     * Fetch shipment details. If successful, assign it to this.shipment
     *
     * @param {String} shipmentId - shipment Id
     * @param {Array} fields - specific shipment fields that you want
     * to fetch, empty means all
     */
    async fetchShipmentDetails({
      shipmentId = this.shipmentId,
      fields = []
    } = {}) {
      if (this.isFetching) return

      this.isFetching = true

      const requestId = 'fetchShipmentDetails'
      const queryParams = buildQueryParams({
        fields
      })

      const url = `${SHIPMENTS_API_URL}/v1/${this.country}/${
        this.tradeType
      }/shipment/${shipmentId}${queryParams}`

      try {
        const { data } = await this.$axios.$get(url, { requestId })
        let shipment = {}

        if (!fields.length) {
          shipment = data
        } else {
          fields.forEach(key => (shipment[key] = data[key]))
        }

        this.setViewedShipment(shipment)
      } catch (err) {
        if (this.$axios.isCancel(err)) return

        if (err.response.status == 404)
          return this.$nuxt.error({
            statusCode: 404,
            message: `Shipment does not exist`
          })

        throw err
      }

      this.isFetching = false
    },

    /**
     * Generate the start and end indices based on the page limit and current page
     *
     * @param {Number} page - current page
     * @param {Number} limit - page limit
     *
     * @returns {Object} start and end indices
     */
    findIndexes(page, limit) {
      const start = (page - 1) * limit
      const end = start + limit
      return { start, end }
    },

    formatDate(date) {
      return this.$date.formatUtc(date, 'yyyy-MM-dd')
    },

    /**
     * Format and add the necessary fields for displaying data based on the shipment field
     *
     * @param {Object} fieldValue - initial shipment field values
     *
     * @returns {Object} updated shipment field values
     */
    formatFieldValue(fieldValue) {
      if (!fieldValue) return fieldValue

      let val = fieldValue
      switch (val.name) {
        case 'Arrival Date':
          val.value = this.formatDate(val.value)
          break
        case 'Address':
        case 'Declared Address':
          val.value = cleanAddress(val.value)
          break
        case 'Notify Party':
        case 'Notify Party Name':
          val.component = 'nuxt-link'
          val.link = this.getCompanyProfileLink(this.shipment.NotifyPartyId)
          break
        case 'Master Bill':
          if (this.noMasterBill) {
            val.value = ''
            break
          }

          val.component = 'nuxt-link'
          val.link = this.masterBillOfLadingLink
          break
        case 'Consignee':
          if (val.value !== emptyText) {
            val.component = 'nuxt-link'
            val.link = this.getCompanyProfileLink(this.shipment.ConsigneeId)
          }
          break
        case 'Shipper':
          if (val.value !== emptyText) {
            val.component = 'nuxt-link'
            val.link = this.getCompanyProfileLink(this.shipment.ShipperId)
          }
          break
        case 'Total TEUs':
          val.value = convertToTwoDigitDecimal(val.value)
          break
      }

      return val
    },

    /**
     * Update the value of each field of the section data to the needed format in the component
     *
     * @param {Array} summary - section summary data
     * @param {Number} spaceIndex - optional, row in the section data table where you want to
     * insert a blank space
     *
     * @returns {Array} Formatted section data
     */
    formatSummary(summary, spaceIndex) {
      return summary.reduce((acc, val, index) => {
        if (!val.value || !val.value.length) return acc

        val = this.formatFieldValue(val)

        if (spaceIndex > 0 && index === spaceIndex && acc.length) acc.push('')

        acc.push(val)
        return acc
      }, [])
    },

    getCompanyProfileLink(companyId) {
      return `/company/${companyId}/overview`
    },

    handleLimitChange(limit) {
      this.pageLimit = limit
    },

    handlePageChange(page) {
      this.currentPage = page
    }
  }
}
</script>
<style src="~/assets/scss/sections/shipments/shipment-details.scss" lang="scss"/>
